import React from 'react';
import '../style/SalesCard.css';
import { useNavigate } from 'react-router-dom';

function SalesCard({ title, subtitle, price, monthly, bulletPoints, dark, className }) {
    let navigate = useNavigate();

    function handleClick() {
        navigate('/contact'); // Use the path of the page you want to navigate to
    }

    return (
        <div className={`sales-card ${className}`}>
            <div className={dark ? 'card-title-dark' : 'card-title'}>
                {title}
            </div>
            <div className='card-subtitle'>{subtitle}</div>
            {monthly ?
                <div className='card-price'>£{price}<sub>/mo</sub></div>
                :
                <div className='card-price'>£{price}</div>
            }
            <div>
                <ul className='tick-list'>
                    {bulletPoints.map((element, index) => (
                        <li key={index}>{element}</li>
                    ))}
                </ul>
            </div>


            <button onClick={handleClick} className='contact-button'>Contact Us</button>
        </div>
    );
}

export default SalesCard;
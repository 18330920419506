import React from 'react';
import 'font-awesome/css/font-awesome.min.css';
import Fade from 'react-reveal/Fade';
import ServiceCard from '../components/ServiceCard';
import InfoCard from '../components/InfoCard';
import backgroundCover from '../images/background-cover.svg';
import geometricDeer from '../images/geometric-deer.svg';
import mobile from '../images/mobile_1.svg';
import mobileTwo from '../images/mobile_2.svg'
import desktop from '../images/desktop.svg';
import lightModeDesignIcon from '../images/light-mode-design-icon.svg';
import lightModeDevelopIcon from '../images/light-mode-develop-icon.svg';
import lightModeDeployIcon from '../images/light-mode-deploy-icon.svg';
import lightModeMaintain from '../images/light-mode-maintain.svg';
import SalesCard from '../components/SalesCard';
import TheDeal from '../components/TheDeal';
import { useNavigate } from 'react-router-dom';


function Homepage() {

    let navigate = useNavigate();

    function handleClick() {
        navigate('/contact'); // Use the path of the page you want to navigate to
    }

    const scrollToSection = () => {
        console.log('scrolling!');
        const firstSection = document.getElementById('homepage-header');
        if (firstSection) {
            console.log('scrolling out og view!');
            const rect = firstSection.getBoundingClientRect();
            const offset = window.pageYOffset + rect.top + rect.height; // Calculate the offset position where scrolling should stop
            window.scrollTo({ top: offset, behavior: 'smooth' });
        }
    };

    return (
        <div className="homepage bg-default">

            <header id='homepage-header' className="homepage-header bg-primary text-default">
                <div className='title-container'>
                    <h1 className='main-banner'>Small Business Web Design, Development and Hosting</h1>
                    <h3 className='main-description'>Discover the artistry of entirely hand-coded websites - no page builders, no WordPress, and no initial fees. Premium quality begins at an affordable rate of just £199 a month.</h3>
                    <button onClick={() => handleClick()} className='cta-button'>Get in touch</button>
                </div>
                <div className='deer-container'>
                    <img className='geometric-deer' alt='web design geometric deer' src={geometricDeer} />
                </div>
                <div className='mobile-deer-container'>
                    <img className='mobile-geometric-deer' alt='web design geometric deer' src={geometricDeer} />
                </div>
                <img className='background-cover' alt='web design background' src={backgroundCover} />
                <button onClick={() => scrollToSection()} className='scroll-button' id='scroll-button'>
                    <i className="fa fa-caret-down" style={{ color: 'white', fontSize: '4rem' }}></i>
                </button>
            </header>
            <div className='homepage-container'>
                <section className="homepage-features bg-default text-secondary">
                    <Fade bottom distance='50px'>
                        <TheDeal />
                    </Fade>
                </section>
                <section id='homepage-about' className="homepage-about bg-tertiary text-secondary">
                    <div className='three-card-holder'>
                        <Fade bottom distance='50px'>
                            {/* <h2>
                                Every Device, One Flawless Web Experience
                            </h2> */}
                            <h2>
                                Every device, one flawless web experience...
                            </h2>
                            <InfoCard
                                imgSrc={mobile}
                                title="Mobile-First Design"
                                description="Our design process begins with creating a mobile-responsive site, which is then scaled up to ensure optimal functionality on tablets and desktops."
                            />
                            <InfoCard
                                imgSrc={desktop}
                                title="Fully Responsive"
                                description="Your website will be tailored to adapt seamlessly across all mobile screen sizes, as well as tablet and desktop displays, ensuring that new clients can visit your site from any device."
                            />
                            <InfoCard
                                imgSrc={mobileTwo}
                                title="Optimisation"
                                description="Considering that over 55% of internet traffic comes from mobile devices, we focus on optimising your mobile site to achieve peak performance in search engine results."
                            />
                        </Fade>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                        <Fade left>
                            <ServiceCard imageSrc={lightModeDesignIcon} text='We <strong>Design</strong>' direction='left' />
                        </ Fade>
                        <Fade right>
                            <ServiceCard imageSrc={lightModeDevelopIcon} text='We <strong>Develop</strong>' direction='right' />
                        </Fade>
                        <Fade left>
                            <ServiceCard imageSrc={lightModeDeployIcon} text='We <strong>Deploy</strong>' direction='left' />
                        </Fade>
                        <Fade right>
                            <ServiceCard imageSrc={lightModeMaintain} text='We <strong>Maintain</strong>' direction='right' />
                        </Fade>
                    </div>
                </section>
                <section>
                    <Fade bottom distance='50px'>
                        <div className='three-card-holder-sales'>
                            {/* <div style={{ width: '60%', margin: '0 auto' }}> */}
                            {/* <Carousel axis="horizontal" autoFocus={true} showArrows={true} showThumbs={false} showStatus={false} useKeyboardArrows={true} verticalSwipe='standard' centerMode={true} centerSlidePercentage={40} emulateTouch={true} selectedItem={1} infiniteLoop={true}> */}
                            <SalesCard
                                title="E-Commerce"
                                subtitle='Contact for more info'
                                price={'7500+'}
                                bulletPoints={['£7500 minimum up front cost', 'Custom Designed', 'Custom Shopify Integration', 'Easy to Edit']}
                                monthly={false}
                            />
                            <SalesCard
                                className="main-offering"
                                title="Standard"
                                subtitle='5 pages'
                                price={199}
                                bulletPoints={['Unlimited Edits', 'Includes Hosting', 'Lifetime Updates', 'Dedicated Customer Service']}
                                monthly={true}
                            />
                            <SalesCard
                                title="One-off Payment"
                                subtitle='5 Pages'
                                price={2800}
                                bulletPoints={['100% Custom Code', '£25/month Hosting', 'Optimised for all devices', '100 speed Google score']}
                                monthly={false}
                                description="60% of all internet traffic is on mobile devices, so we optimise your mobile to perform their best in search engines."
                            />
                            {/* </Carousel> */}
                            {/* </div> */}
                        </div>
                    </Fade>
                </section>
                {/* This is a */}
                {/* <section className="homepage-testimonials bg-tertiary text-secondary">
                    <h2>What Our Clients Say</h2>
                    <Fade left>
                        <blockquote>
                            <p>"With Jeremys expertise, we were able to integrate new technology to leverage our data to the fullest extent."</p>
                            <cite>- Director of Network Intelligence, International Satellite Communications Company</cite>
                        </blockquote>
                    </Fade>
                    <Fade right>
                        <blockquote>
                            <p>"Thanks to their guidance, we refined our workflows and saw substantial reductions in overhead."</p>
                            <cite>- Head of Business Intelligence, International Media Group</cite>
                        </blockquote>
                    </Fade>
                    <Fade left>
                        <blockquote>
                            <p>"Their web development was essential in giving our business a professional online presence."</p>
                            <cite>- Director, Software Consultancy</cite>
                        </blockquote>
                    </Fade>
                </section> */}

                {/* <section className="homepage-cta bg-accent text-default">
                    <h2>Ready to Transform Your Business?</h2>
                    <p>Contact us today for a free consultation.</p>
                    <button className="btn-primary">Contact Us</button>
                </section> */}
            </div>

        </div>
    );
}

export default Homepage;

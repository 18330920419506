import React from "react";


const ListItem = ({ title, description }) => {

    return (
        <div className="checklist-item">
            <h3 style={{textAlign: 'left', paddingTop: '15px', fontWeight: 'bold'}}><strong>{title}</strong></h3>
            <p style={{textAlign: 'left', opacity: 0.8}}>{description}</p>
        </div>
    )

}

export default ListItem;



import React from 'react';
import '../style/InfoCard.css';

function InfoCard({imgSrc, title, description}) {
    return (
        <div className="card">
            <img src={imgSrc} alt={title} className="card-image" />
            <h2 className="info-card-title">{title}</h2>
            <p className="card-description">{description}</p>
        </div>
    );
}

export default InfoCard;
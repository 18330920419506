import React from 'react';
import PropTypes from 'prop-types';
import './../style/ServiceCard.css';  // Import a CSS file to style your component

function ServiceCard({ text, imageSrc, direction }) {
    if (direction === 'left') {
        return (
            <div className="service-card">
                <div className="icon-container">
                    <img width='100' height='100' src={imageSrc} alt="" className="service-icon" />
                </div>
                <div className="text-container">
                    <p className="service-text" dangerouslySetInnerHTML={{ __html: text }}></p>
                </div>
            </div>
        );
    } else {
        return (
            <div className="service-card">
                <div className="text-container">
                    <p className="service-text" dangerouslySetInnerHTML={{ __html: text }}></p>
                </div>
                <div className="icon-container">
                    <img width='100' height='100' src={imageSrc} alt="" className="service-icon" />
                </div>
            </div>
        )
    }

}

ServiceCard.propTypes = {
    text: PropTypes.string.isRequired,
    imageSrc: PropTypes.string.isRequired,
};

export default ServiceCard;

import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Homepage from './pages/Homepage';
import LoadingComponent from './components/LoadingComponent';
import { useMessage } from './helpers/MessageContext';
import AlertStack from './helpers/AlertStack';
// import Contact from './pages/Contact';
// import Footer from './components/Footer';
// import NavBar from './components/NavBar';
// import WhoWeAre from './pages/WhoWeAre';
// import WhatWeDo from './pages/WhatWeDo';
// import Pricing from './pages/Pricing';

// const Homepage = React.lazy(() => import('./pages/Homepage'));

const Contact = React.lazy(() => import('./pages/Contact'));
const WhoWeAre = React.lazy(() => import('./pages/WhoWeAre'));
const WhatWeDo = React.lazy(() => import('./pages/WhatWeDo'));
const Pricing = React.lazy(() => import('./pages/Pricing'));
const PostSignUp = React.lazy(() => import('./pages/PostSignUp'));
const PrivacyPolicy = React.lazy(() => import('./pages/PrivacyPolicy'));
const TermsOfService = React.lazy(() => import('./pages/TermsOfService'));
const NavBar = React.lazy(() => import('./components/NavBar'));
const Footer = React.lazy(() => import('./components/Footer'));



function App() {
  const messageContext = useMessage();
  console.log('messageContext', messageContext); // Check what this logs
  const { errorMessage, successMessage, timestamp } = messageContext;

  // const { errorMessage, successMessage, timestamp } = useMessage();

  return (
    <div>
      <NavBar />
        <AlertStack successMessage={successMessage} errorMessage={errorMessage} timestamp={timestamp} />
        <Router>
          <div className="App">
            <Suspense fallback={<LoadingComponent />}>
              <Routes>
                <Route path="/" exact element={<Homepage />} />
                <Route path="/who-we-are" exact element={<WhoWeAre />} />
                <Route path="/what-we-do" element={<WhatWeDo />} />
                <Route path="/pricing" element={<Pricing />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/post-sign-up" element={<PostSignUp />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/terms-of-service" element={<TermsOfService />} />
              </Routes>
            </Suspense>
            <Footer />
          </div>
        </Router>

    </div >
  );
}

export default App;





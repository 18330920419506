import React from 'react';
import dealImage from '../images/deal-image.svg';
import { useNavigate } from 'react-router-dom';
import ListItem from './ListItem';

function TheDeal() {
    let navigate = useNavigate();

    function handleClick() {
        navigate('/contact'); // Use the path of the page you want to navigate to
    }

    return (
        <>
            <div className='deal-container'>
                <div className="deal-image">
                    <img width='640' height='360' src={dealImage} alt="Web Development in progress" />
                </div>
                <div className="pricing-highlight">
                    <h2>
                        <strong className="primary-color">£0 Downpayment</strong>, £199 Per Month,
                        <strong> 12 Month Minimum Contract</strong>
                    </h2>
                    <p>
                        <strong>£0 down</strong> for a standard 5 page small business website. If you're looking for something more complex, we can create a custom pricing plan that fits the scale of your project, including any additional pages and the specific timeframe required.
                    </p>
                    <p>
                        You <strong>own</strong> your domain and content.
                        <strong> Cancel anytime</strong>, after 12 months, with no fees or hassle.
                    </p>
                    <p>
                        Our price: <strong className="primary-color">£199/month</strong>.
                    </p>
                    <button className='contact-button' onClick={handleClick}>Contact Us</button>
                </div>
            </div>
            <ul>
                <li className='tick-li'>
                    <ListItem title='Hosting Fees Included' description='Hosting fees are conveniently included in the monthly payment plan.' />
                </li>
                <li>
                    <ListItem title='Unlimited Edits' description='Feel free to request any changes you desire; we guarantee updates within 72 hours.' />
                </li>
                <li>
                    <ListItem title='Dedicated Support' description="Reach out to us directly whenever you need to; you'll always be greeted by a real person, not an automated response." />
                </li>
                <li>
                    <ListItem title='Web Design & Development' description='The package encompasses more than 40 hours dedicated to design, development, and rigorous testing worth £2800.' />
                </li>
                {/* <li>
                    <ListItem title='Green Google Page Speed Score' description='Our optimization ensures your site can attain an excellent green mobile speed on Google PageSpeed Insights, a higher score means a better rank on Google.' />
                </li> */}
                <li>
                    <ListItem title='Green Google Page Speed Score' description='Our optimization ensures your site can attain an excellent green mobile speed on Google PageSpeed Insights, a higher score means a faster website and a better search enginer ranking on Google.' />
                </li>
                <li>
                    <ListItem title='Google Analytics' description='We provide a complimentary installation of Google Analytics to track your traffic sources and monitor visitor behavior.' />
                </li>
            </ul>
        </>
    );
}

export default TheDeal;
